import React from 'react';
import downsizeImage from '../../utils/downsizeImage';
import { useLinkTracking } from '../../hooks/analytics/useLinkClickTracking';
import styles from './discussionImage.module.scss';

const DiscussionImage = ({
  alt_text,
  attribution,
  buzz,
  id,
  index,
  images,
}) => {
  const downsizeImageUrl = (image) => ({ ...image, url: downsizeImage(image.url, 700)});
  const dimensions = (image) => ({ width: image.width, height: image.height });

  const image = downsizeImageUrl(images.standard ?? images.original);
  const mobileImage = images.mobile ? downsizeImageUrl(images.mobile) : image;
  const linkRef = useLinkTracking(buzz, index);

  return (
    <figure className={styles.discussionImage} ref={linkRef}>
      <div id={id} className="subbuzz-anchor" />
      <picture>
        <source media="(min-width: 52rem)" srcSet={`${image.url} 1600w`} {...dimensions(image)} />
        <source srcSet={`${mobileImage.url} 800w`} {...dimensions(mobileImage)} />
        <img src={image.url} alt={alt_text} {...dimensions(image)} />
      </picture>
      <div
        className={styles.attribution}
        dangerouslySetInnerHTML={{ __html: attribution }}
      />
    </figure>
  );
};

export default DiscussionImage;
