import React, { useEffect, useState, useContext, useRef } from "react";
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { getPageSessionId } from '@buzzfeed/adlib/dist/module/services/page-targeting';
import { CID, getPlayerIds } from '@buzzfeed/react-components/lib/utils/connatix';
import CommentsContext from '../../../contexts/comments';
import { isNews } from '../../../utils/isNews';
import { isBackfillEligible, isStickyVideoEligible, hasConnatixVideo } from '../../../utils/connatixVideo';
import { useFloatingMode } from '../useFloatingMode';
import styles from './elements.module.scss';

export default function ElementsPlayer({ buzz = {}, showOnTopQuiz = false }) {
  const [cnxPixel, setCnxPixel] = useState(null);
  const adsContext = useContext(AdsContext);
  const [playerApi, setPlayerApi] = useState(null);
  const [pageSessionId, setPageSessionId] = useState(null);
  const containerId = useRef(null);
  const hasInitialized = useRef(false);
  const showElements = isBackfillEligible(buzz) || (!hasConnatixVideo(buzz) && showOnTopQuiz);
  const { isCommentsPanelOpen } = useContext(CommentsContext);

  // Connatix script is loaded from the head
  // change connatix player based on user country
  // specifically for AU/NZ, and Japan
  let userCountry = adsContext?.env?.userCountry;
  let gdpr = adsContext.gdpr;
  const destination = isNews(buzz) ? 'buzzfeed_news' : 'buzzfeed';
  const { embedId, playerId } = getPlayerIds('backfill', destination, showOnTopQuiz);
  let scriptId = `player-${embedId}`;

  useEffect(() => {
    if (!gdpr) {
      return;
    }

    getPageSessionId({gdpr}).then(psi => {
      setPageSessionId(psi);
    });
  }, [gdpr]);

  useEffect(() => {
    // rendering player before userCountry can result in duplicate players in JP
    if (!showElements || !userCountry || hasInitialized.current || !pageSessionId) {
      return;
    }
    hasInitialized.current = true;

    if (!cnxPixel) {
      const pixelUrl = `https://capi.connatix.com/tr/si?token=${playerId}&cid=${CID}`;
      const pixelImg = new Image();
      pixelImg.src = pixelUrl;
      setCnxPixel(pixelImg);
    }

    window.cnx.cmd.push(() => {
      const player = window.cnx({
        playerId,
        customParam1: pageSessionId,
        settings: {
          rendering: {
            insertPosition: window.cnx.configEnums.InsertPositionEnum.WithinContainer,
          }
        }
      });
      player.render(scriptId, (renderError, api) => {
        if (renderError) {
          console.error('Player rendering failed:', renderError);
          return;
        }
        setPlayerApi(api);
      });
    });
  }, [showElements, userCountry, playerId, scriptId, cnxPixel, pageSessionId]);

  const shouldEnableFloating = (
    playerApi &&
    window.innerWidth <= 1025 &&
    isStickyVideoEligible(buzz) &&
    !isCommentsPanelOpen
  ) || false;
  useFloatingMode(playerApi, containerId, shouldEnableFloating);

  if (!showElements || !userCountry) {
    return '';
  }

  return (
    <div className={`bf-cnx-override ${showOnTopQuiz ? styles.bfElementsWrapperQuiz : styles.bfElementsWrapper}`}>
      <div id={scriptId} ref={containerId} className="bf-cnx-player"></div>
    </div>
  );
}
