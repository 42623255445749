import React, {
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/nextjs';
import { useBreakpoint, ErrorBoundary } from '@buzzfeed/react-components';
import SkipToContent from '@buzzfeed/react-components/lib/components/SkipToContent';
import { isNews } from '../../../utils/isNews';
import { isSponsored } from '../../../utils/isSponsored';
import { ActionBar } from '../../ActionBar';
import AdUnit from '../../Ads/AdUnit';
import BuzzNewsFooter from '../../NewsFooter';
import CommentsWrapper from '../../CommentsWrapper';
import ContentBottom from '../../ContentBottom';
import CommentsContext from '../../../contexts/comments';
import DestinationContext from '../../../contexts/destination';
import EmojiReactions from '../../EmojiReactions';
import FeatureImage from './components/FeatureImage';
import FooterCommunity from '../../FooterCommunity';
import Headline from '../../Headline';
import HeadlineLookbook from '../../Headline/components/HeadlineLookbook';
import LazyLoad from '../../LazyLoad';
import NewsletterSignup from '../../NewsletterSignup/lazy';
import Outbrain from '../../Outbrain';
import Package from '../../Package/lazy';
import { PageOverlay } from '../../PageOverlay';
import Reactions from '../../Reactions/lazy';
import ReactionsAnimated from '../../ReactionsAnimated/lazy';
import { RelatedRecircList, BottomRecircList } from '../../RecircList/variants';
import StudiosPromoUnit from '../../StudiosPromoUnit';
import Subbuzzes from '../../Subbuzzes';
import TaboolaJP from '../../Taboola/JP';
import TaboolaAPAC from '../../Taboola/APAC';
import TopicPromo from '../../TopicPromos';
import TopicTags from '../../TopicTags';
import TrendingProducts from '../../TrendingProducts';
import TrendingShoppingCarousel from '../../TrendingShoppingCarousel/lazy';
import layoutStyles from '../post.module.scss';
import ABeagleContext from "../../../contexts/abeagle";


function FeaturedPost({
  buzz,
  subbuzzData,
  badges,
  showOverlay,
  setShowOverlay,
}) {
  const ref = useRef(null);
  const { destination } = useContext(DestinationContext);
  const { commentsEnabled } = useContext(CommentsContext);
  const isMobile = useBreakpoint('(max-width:500px)'); // page level break is based on bf_header_ui break
  const [commentCount, setCommentCount] = useState(null);
  const { experiments, getExperimentValue, getFeatureFlagValue } = useContext(ABeagleContext);
  const [isShowShoppingBFN, setIsShowShoppingBFN] = useState(false);
  const [taboolaAPACFF, setTaboolaAPAC] = useState(false);

  const showTopShareBar =
    (!(buzz.shouldHideBuzzSharing || buzz.shouldShowPostContentOnly) &&
      !isMobile) ||
    isNews(buzz);
  const showConfettiWithReactions = buzz.isTrivia || buzz.isPersonality;
  const showEmojiReactions = !!(buzz.published && parseInt(buzz.published, 10) > 1710959400);

  useEffect(() => {
    if (isNews(buzz)) {
      document.querySelector('body').style.overflowX = 'hidden';
    }
  }, [buzz, buzz.format.page_width]);

  // All shopping recirc tests go here
  useEffect(() => {
    const shoppingModuleBFN = getExperimentValue('RT-946-shopping-recirc-bfn', {
      rejectErrors: false,
    });

    if (isNews(buzz) && shoppingModuleBFN === 'var1') {
      setIsShowShoppingBFN(true);
    }
  }, [getExperimentValue]);

  useEffect(() => {
    if (experiments && experiments.loaded) {
      const variant = getFeatureFlagValue('taboola_apac');
      setTaboolaAPAC(variant);
    }
  }, [experiments, getFeatureFlagValue]);

  const onOverlayClick = useCallback(() => {
    setShowOverlay(false);
  }, [setShowOverlay]);

  return (
    <main
      className={`${layoutStyles.main} ${
        layoutStyles[destination]} embed-content`}
      id="buzz-content"
      ref={ref}
    >
      {buzz.longform_custom_header && (
        <FeatureImage data={buzz.longform_custom_header} />
      )}

      <div
        className={`${layoutStyles.article} ${
          layoutStyles[destination]} ${
          layoutStyles.wide
        } ${layoutStyles[buzz.bfpTheme] || ''} embed-post`}
      >
        <div className={layoutStyles.content}>
          <article className="featured-post">
            {buzz.lookbookPalette
              ? <HeadlineLookbook
                  buzz={buzz}
                  commentCount={commentCount}
                  showShareBar={showTopShareBar}
                />
              : (
                  <>
                    <Headline buzz={buzz} badges={badges} />
                    <ActionBar
                      buzz={buzz}
                      commentCount={commentCount}
                      showShareBar={showTopShareBar}
                      destination={destination}
                    />
                  </>
                )
            }
            <TrendingProducts buzz={buzz} postRef={ref} />
            <Subbuzzes
              buzz={buzz}
              subbuzzData={subbuzzData}
              pixiedust={{
                unit_type: 'buzz_body',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />
            {buzz.hasStudiosPromoUnit && <StudiosPromoUnit buzz={buzz} />}
            {isNews(buzz) && <TopicTags buzz={buzz} />}
            <BuzzNewsFooter buzz={buzz} />
            <ContentBottom buzz={buzz} />
          </article>

          <ErrorBoundary onError={captureException}>
            <AdUnit
              type="subbuzz"
              pixiedust={{
                unit_type: 'buzz_bottom',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />
            {taboolaAPACFF && (
              <LazyLoad>
              <TaboolaAPAC />
            </LazyLoad>
            )}
            {buzz.classification.edition === 'Japan' && (
              <LazyLoad>
                <TaboolaJP isMobile={isMobile}/>
              </LazyLoad>
            )}
            {!showEmojiReactions && (showConfettiWithReactions ? <ReactionsAnimated /> : <Reactions />)}
            {showEmojiReactions && <EmojiReactions contentId={buzz.id} isSponsored={isSponsored(buzz)}/>}
            {commentsEnabled && <CommentsWrapper
              buzz={buzz}
              commentCount={commentCount}
              onCommentsLoaded={setCommentCount}
              firstPage={100}
              repliesFirstPage={2}
              perPage={100}
            />}
            {commentsEnabled && <TopicPromo buzz={buzz} subunitName="comments" subunitType="component" unitType="buzz_bottom" />}
            {
              destination === 'buzzfeed' &&
              !buzz.isShopping &&
              !isNews(buzz) &&
              <>
                <Package
                  style={{ marginBottom: '3rem' }}
                  isNewDesign={true}
                />
                <AdUnit
                  type="story-bpage-desktop"
                  pixiedust={{
                    unit_type: 'buzz_bottom',
                    unit_name: buzz.id,
                    position_in_unit: 1,
                  }}
                  style={{ marginTop: '-2rem' }}
                />
              </>
            }
            <RelatedRecircList />
            {
              destination === 'buzzfeed' &&
              buzz.isShopping &&
              !isNews(buzz) &&
              <>
                <AdUnit
                  type="story-bpage-desktop"
                  pixiedust={{
                    unit_type: 'buzz_bottom',
                    unit_name: buzz.id,
                    position_in_unit: 1,
                  }}
                />
                <Package
                  style={{ marginTop: isMobile ? '1rem' : '-2rem' }}
                  isNewDesign={true}
                />
              </>
            }
            <NewsletterSignup />
            <FooterCommunity />
            {isMobile && (
              <AdUnit
                type="bigstory"
                pixiedust={{
                  unit_type: 'buzz_bottom',
                  unit_name: buzz.id,
                  position_in_unit: 1,
                }}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary onError={captureException}>
        <TrendingShoppingCarousel />
        {buzz.classification.edition === 'Japan' && (
          <LazyLoad>
            <TaboolaJP placement={2} isMobile={isMobile}/>
          </LazyLoad>
        )}
        {isNews(buzz) && <SkipToContent jumpTo="news-footer" className="skipToFooter" label="Skip to footer" />}
        {isNews(buzz) &&
          (
            isShowShoppingBFN ?
            <Package
              style={{
                marginBottom: '3rem',
                marginTop: '-2rem',
              }}
              isNewDesign={false}
            />
            :
            <Outbrain />
          )
        }
        <BottomRecircList />
      </ErrorBoundary>
      {showOverlay && <PageOverlay onOverlayClick={onOverlayClick} />}
    </main>
  );
}

FeaturedPost.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array,
  subbuzzData: PropTypes.shape({
    assets: PropTypes.object,
    subbuzzes: PropTypes.array,
  }),
};

export default FeaturedPost;
