import { useEffect, useRef, useContext, useState } from 'react';
import {
  getOriginOverride,
  getOrigin,
  getExperimentSubtags,
  getSource,
  getPubHubId,
  getReferrerLabel,
  getBioId,
} from '@buzzfeed/react-components/lib/utils/affiliate';
import {
  setSubtags,
  isAmazon,
} from '@buzzfeed/bf-utils/lib/affiliate-link';
import ABeagleContext from '../../contexts/abeagle';

/**
 * Added Button’s PostTap prefix to link href
 */
const modifyHREF = ({ element, experiments }) => {
  if (!element) {
    return;
  }

  let href = element.href;

  if(isAmazon(href)) {
    const updates = {
      platform: getSource(),
      origin: getOrigin({ origin: getOriginOverride(element) }),
      experiment: getExperimentSubtags(experiments),
      pubhubId: getPubHubId(),
      bioId: getBioId(),
      linkId: element.getAttribute('data-vars-link-id') || '0',
      module: '0',
      rank: '0',
    };
    // only overwrite origin and linkId if they are nonempty
    for (const key of ['origin', 'linkId']) {
      if (!updates[key] || updates[key] === '0') {
        delete updates[key];
      }
    }
    href = setSubtags(href, updates) + `%2C${getReferrerLabel() || '0'}`;
  }


  // We're currently unable to bump bf-utils without risking an incident
  // For now, add referrer outside of setSubtags function
  element.href = 'https://r.bttn.io?btn_ref=org-21949126c81417e9&btn_url=' +
    encodeURIComponent(href);
};

/**
 * hrefModifier should be a function that runs at the end of the async modifyHREF function.
 */
export function useButtonPostTap({buzz = {}}) {
  const ref = useRef(null);
  const { experiments, getExperimentValue, getFeatureFlagValue } = useContext(ABeagleContext);
  const [buttonPostTapEnabled, setButtonPostTapEnabled] = useState(false);
  const [buttonPostTapNonAmazonEnabled, setButtonPostTapNonAmazonEnabled] = useState(false);

  useEffect(() => {
    if (experiments && experiments.loaded) {
      setButtonPostTapEnabled(getExperimentValue('RT-1150-ButtonPostTap-abtest', {
        rejectErrors: false,
      }) === 'control');
      setButtonPostTapNonAmazonEnabled(getFeatureFlagValue('RT-1510-ButtonPostTap-Non-Amazon'));
    }
  }, [experiments, getExperimentValue]);

  useEffect(() => {
    const element = ref.current;

    if (!element || (!buttonPostTapEnabled && !buttonPostTapNonAmazonEnabled)) {
      return;
    }

    element.querySelectorAll('a[href]').forEach(link => {

      if (isAmazon(link.href) && !buttonPostTapEnabled) {
        return;
      } else if (!isAmazon(link.href) && !buttonPostTapNonAmazonEnabled) {
        return;
      }

      // only modify ButtonPostTaplinks once
      if (link.classList.contains('js-buttonposttap-modified')) {
        return;
      }
      link.classList.add('js-buttonposttap-modified');
      // prevent handle this link by skimlinks
      link.classList.add('noskim');

      modifyHREF({ element: link, experiments });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonPostTapEnabled, experiments]);

  return ref;
}
