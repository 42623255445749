import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import {
  image_service_url as imageRoot,
  destinations,
  CLUSTER
} from '../../constants';
import CommentsContext from '../../contexts/comments';
import DestinationContext from '../../contexts/destination';
import { isAddYours } from '../../utils/isAddYours';
import { isDiscussionPage } from '../../utils/isDiscussionPage';
import { isNews } from '../../utils/isNews';
import dynamicImport from '../../utils/dynamic-import';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { useCommentsTracking } from '../../hooks/analytics/useCommentsTracking';
import { useCommentsCtaButtonTracking } from '../../hooks/analytics/useCommentsCtaButtonTracking';

const Comments = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "comments" */ '@buzzfeed/react-components/dist/module/lib/components/Comments'
    ),
  { ssr: false }
);

const CommentsCtaWrapper = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "comments-cta-wrapper" */ '../CommentsCtaWrapper'
    ),
  { ssr: false }
);

const CondensedCommentsWrapper = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "condensed-comments" */ '../CondensedCommentsWrapper'
    ),
  { ssr: false }
);

function CommentsWrapper({
  buzz,
  commentCount,
  firstPage,
  repliesFirstPage,
  perPage,
  onCommentsLoaded,
}) {
  const { base_url: apiRoot, destination } = useContext(DestinationContext);
  const {
    isCommentsPanelOpen,
    toggleCommentsPanel,
  } = useContext(CommentsContext);
  const [ctaState, setCtaState] = useState({
    ctaCommentText: '',
    syncCtaToSidebar: false,
    syncSidebarToCta: false,
  });
  const condensedComments = !isDiscussionPage(buzz) && !buzz.isCommentsPage && buzz.classification.edition !== 'Japan';
  const { base_url: bfBaseUrl } = destinations['buzzfeed'];
  const commentsTrackingFn = useCommentsTracking(buzz);
  const commentsBottomCtaButtonTrackingFn = useCommentsCtaButtonTracking(buzz, {
    unit_type: 'buzz_bottom',
    subunit_name: 'comments_cta_section'
  });
  const loginRedirectUrl = isNews(buzz)
    ? `${apiRoot}/auth/sso/signin?redirect=${buzz.canonical_url}`
    : undefined;
  const locale = buzz.language || 'en';

  const onCloseComments = useCallback(() => {
    toggleCommentsPanel(false);
  }, [toggleCommentsPanel]);

  const onOpenComments = useCallback(() => toggleCommentsPanel(true), [toggleCommentsPanel]);

  const commentsApiRoot = CLUSTER === 'dev' ? bfBaseUrl : apiRoot;
  const comments = <Comments
    apiRoot={commentsApiRoot}
    profileRoot={bfBaseUrl}
    imageRoot={imageRoot}
    buzzId={+buzz.id}
    firstPage={firstPage}
    repliesFirstPage={repliesFirstPage}
    perPage={perPage}
    track={commentsTrackingFn}
    locale={locale}
    loginRedirectUrl={loginRedirectUrl}
    onLoaded={onCommentsLoaded}
    allowImages={isAddYours(buzz)}
    className={classNames({
      'themeNews': isNews(buzz),
      'condensedComments': condensedComments,
      'discussionPage': isDiscussionPage(buzz),
    })}
    showCloseButton={condensedComments}
    onCloseButtonClick={onCloseComments}
    onOpenComments={onOpenComments}
    shouldSortByDate={buzz.tags.includes('--sort-comments-latest')}
    ctaState={ctaState}
  />;

  return(
    <div className={classNames(styles.commentsWrapper, {
      [styles['condensedComments']]: condensedComments && !isNews(buzz)
    })}>
      {!condensedComments && (
        <div id="comments" className={styles.comments}>
          {comments}
        </div>
      )}
      {condensedComments && (
        <>
          <CommentsCtaWrapper
            buzz={buzz}
            commentCount={commentCount}
            commentsApiRoot={commentsApiRoot}
            locale={locale}
            destination={destination}
            ctaState={ctaState}
            setCtaState={setCtaState}
            onOpenComments={onOpenComments}
            toggleCommentsPanel={toggleCommentsPanel}
            isCommentsPanelOpen={isCommentsPanelOpen}
            commentsBottomCtaButtonTrackingFn={commentsBottomCtaButtonTrackingFn}
            isNews={isNews(buzz)}
            track={commentsTrackingFn}
          />
          <CondensedCommentsWrapper
            buzz={buzz}
            isCommentsPanelOpen={isCommentsPanelOpen}>
              {comments}
          </CondensedCommentsWrapper>
        </>
      )}
    </div>
  );
}

CommentsWrapper.propTypes = {
  buzz: PropTypes.object,
  firstPage: PropTypes.number,
  repliesFirstPage: PropTypes.number,
  perPage: PropTypes.number,
}

export default CommentsWrapper;
