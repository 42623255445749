import { useMemo } from 'react';
import {
  trackClientImpression,
  trackClientExternalLink,
} from './client-event-tracking';

export function useGatingTracking(buzz, trackingData = {}) {
  return useMemo(
    () => ({
      externalLink: eventData => {
        trackClientExternalLink(buzz, {
          ...trackingData,
          ...eventData
        });
      },
      impression: eventData => {
        trackClientImpression(buzz, {
          ...trackingData,
          ...eventData
        });
      },
    }),
    [buzz, trackingData]
  );
}
