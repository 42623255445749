import { objectToQueryString } from '@buzzfeed/bf-utils/lib/query-string';
import {
  BackToTop,
  CookieBanner,
  useConsent,
  withErrorBoundary,
} from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import Router from 'next/router';
import React, { memo, useEffect, useMemo, useState } from 'react';
import ABeagleProvider from '../../../components/ABeagleProvider';
import Ads from '../../../components/Ads/Ads';
import AdUnit from '../../../components/Ads/AdUnit';
import CommentsManager from '../../../components/CommentsManager';
import ConsentManagement from '../../../components/ConsentManagement';
import { DestinationWrapper } from '../../../components/DestinationWrapper';
import Post from '../../../components/Post';
import CommentsPost from '../../../components/Post/CommentsPost';
import DiscussionPost from '../../../components/Post/DiscussionPost';
import PressBoardAnalytics from '../../../components/PressBoardAnalytics';
import PromoPopup from '../../../components/PromoPopup';
import ResizeManager from '../../../components/ResizeManager';
import SiteHeader from '../../../components/SiteHeader';
import StickyManager from '../../../components/StickyManager';
import NewsPageFooter from '../../../components/NewsPageFooter';
import { destinations } from '../../../constants';
import { BuzzProvider } from '../../../contexts/buzz';
import { ConsentProvider } from '../../../contexts/consent';
import { useAddressabilityTracking } from '../../../hooks/analytics/useAddressabilityTracking';
import { useBreakingBarTracking } from '../../../hooks/analytics/useBreakingBarTracking';
import { useNavActionTracking } from '../../../hooks/analytics/useNavActionTracking';
import { useNewsBreakingTickerTracking } from '../../../hooks/analytics/useNewsBreakingTickerTracking';
import { usePageviewTracking } from '../../../hooks/analytics/usePageviewTracking';
import { usePageviewCommentsTracking } from '../../../hooks/analytics/usePageviewCommentsTracking';
import { usePerformanceTracking } from '../../../hooks/analytics/usePerformanceTracking';
import { useScrollDepthTracking } from '../../../hooks/analytics/useScrollDepthTracking';
import { useTimeSpentTracking } from '../../../hooks/analytics/useTimeSpentTracking';
import useTrackonomics from '@buzzfeed/react-components/lib/hooks/useTackonomics';
import { useContentLayoutStability } from '../../../hooks/useContentLayoutStability';
import { useSessionTracking } from '../../../hooks/useSessionTracking';
import { i18n, withTranslation } from '../../../i18n';
import dynamicImport from '../../../utils/dynamic-import';
import { fetchAPI } from '../../../utils/initialProps';
import { isDiscussionPage as _isDiscussionPage } from '../../../utils/isDiscussionPage';
import { useLocalBuzzTracking } from '../../../utils/localBuzzTracking';
import styles from './[slug].module.scss';
import AwarenessAdUnit from '../../../components/Ads/components/AwarenessAd';
import DesktopStickyAd from '../../../components/Ads/components/DesktopStickyAd';
import { ShoppingHeader } from '../../../components/ShoppingHeader';
import Wunderkind from '../../../components/Wunderkind';
import ToolbarAdUnit from '../../../components/Ads/components/ToolbarAd';
import BottomDrawer from '../../../components/BottomDrawer';
import { languageFromEdition, normaliseEdition } from '../../../utils/editions';
import { headerBrand } from '../../../utils/brands';

const BreakingBar = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "breaking-bar" */ '@buzzfeed/react-components/dist/module/lib/components/BreakingBar'
    ),
  { ssr: false }
);

const NewsBreakingTicker = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "news-breaking-ticker" */ '@buzzfeed/react-components/dist/module/lib/components/NewsBreakingTicker'
    ),
  { ssr: false }
);

// Page view tracking depends on A/B test context in some cases
// Separate this component so it can be nested in ABeagleProvider
// Normally we would want these hooks to live directly in the Page component
const CommonPageLevelTracking = withErrorBoundary(
  ({ buzz }) => {
    useTimeSpentTracking(buzz);
    useScrollDepthTracking(buzz);
    usePerformanceTracking(buzz);
    return null;
  },
  {
    onError: captureException,
  }
);

const BuzzPageLevelTracking = withErrorBoundary(
  ({ buzz }) => {
    useAddressabilityTracking(buzz);
    usePageviewTracking(buzz);
    useLocalBuzzTracking(buzz.id);
    useSessionTracking(buzz.id);
    useTrackonomics(buzz.id);
    return null;
  },
  {
    onError: captureException,
  }
);

const CommentsPageLevelTracking = withErrorBoundary(
  ({ buzz }) => {
    usePageviewCommentsTracking(buzz);
    return null;
  },
  {
    onError: captureException,
  }
);

export const Page = memo(function Page({
  buzz,
  badges,
  subbuzzData,
  header,
  giftguideHeader,
  userEdition,
}) {
  const [
    memoizedBuzz,
    memoizedBadges,
    memoizedSubbuzzData,
    memoizedGiftguideHeader,
    memoizedUserEdition,
  ] = useMemo(
    () => [buzz, badges, subbuzzData, giftguideHeader, userEdition],
    [buzz.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isCommentsPage = memoizedBuzz.isCommentsPage;
  const isDiscussionPage = _isDiscussionPage(memoizedBuzz);

  const { consentValue, isConsentKnown, isConsentReady } =
    useConsent('tracking');

  const breakingBarTrackingFn = useBreakingBarTracking(
    memoizedBuzz,
    destinations[buzz.destination_name].base_url
  );

  const newsBreakingTickerFn = useNewsBreakingTickerTracking(memoizedBuzz);
  const [showOverlay, setShowOverlay] = useState(false);

  const buzzEdition = normaliseEdition(memoizedBuzz.classification.edition);

  // always show local edition navigation
  // when both content and user's edition are English
  // see https://buzzfeed.atlassian.net/browse/OOSITE-1189
  const headerEdition = [memoizedUserEdition, buzzEdition]
    .map(languageFromEdition)
    .every(lang => lang === 'en')
    ? userEdition
    : buzzEdition;

  const brand =
    headerBrand(memoizedBuzz?.metavertical) ||
    headerBrand(memoizedBuzz?.classification?.section?.toLowerCase());

  const isNews = memoizedBuzz.destination_name === 'buzzfeed_news';

  const renderHeaderAboveAd = headerEdition === 'us' && !brand && !isNews;

  /**
   * @todo
   * Prevent execution if subbuzz data does not include any of the configured content (freeform
   * embeds and tweets). Also, if there are supported subbuzzes and they do not yet have width and
   * height data, increase the sampling rate
   * Does not need to be enabled for comments pages, as subbuzzes do not appear
   */
  useContentLayoutStability({ enabled: !(isCommentsPage || isDiscussionPage) });

  useEffect(() => {
    if (i18n.language !== buzz.language) {
      i18n.changeLanguage(buzz.language);
    }
  }, [buzz.language]);

  return (
    <>
      <BuzzProvider value={memoizedBuzz}>
        <ConsentProvider
          value={{
            tracking: {
              consentValue,
              isConsentKnown,
              isConsentReady,
            },
          }}
        >
          <ABeagleProvider>
            <DestinationWrapper destination={memoizedBuzz.destination_name}>
              <ConsentManagement />
              <Wunderkind />
              <CommonPageLevelTracking buzz={memoizedBuzz} />
              {isCommentsPage ?
                <CommentsPageLevelTracking buzz={memoizedBuzz} /> :
                <BuzzPageLevelTracking buzz={memoizedBuzz} />}
              <ResizeManager>
                <StickyManager buzz={memoizedBuzz}>
                  <CommentsManager buzz={memoizedBuzz}>
                    {isDiscussionPage && <Ads buzz={memoizedBuzz} badges={memoizedBadges}>
                      {!buzz.isAd && <AdUnit type="pixel" />}
                      {!buzz.isAd && <AwarenessAdUnit buzz={memoizedBuzz}/>}
                      {!buzz.isAd && memoizedBuzz.country_code !== 'ja-jp' && (
                        <AdUnit type="toolbar" />
                      )}
                      <SiteHeader {...header} />
                      <DiscussionPost
                        buzz={memoizedBuzz}
                        badges={memoizedBadges}/>
                    </Ads>}
                    {!isDiscussionPage && <Ads buzz={memoizedBuzz} badges={memoizedBadges}>
                      {renderHeaderAboveAd && <SiteHeader {...header} />}
                      {!buzz.isAd && <AdUnit type="pixel" />}
                      {!buzz.isAd && <AwarenessAdUnit buzz={memoizedBuzz}/>}
                      {!buzz.isAd && memoizedBuzz.country_code !== 'ja-jp' && (
                        <ToolbarAdUnit buzz={memoizedBuzz}/>
                      )}
                      {memoizedBuzz.isNews && (
                        <NewsBreakingTicker track={newsBreakingTickerFn} />
                      )}
                      {!renderHeaderAboveAd && <SiteHeader {...header} />}
                      {!isCommentsPage && memoizedBuzz.isShopping &&
                        memoizedBuzz.country_code === 'en-us' && (
                          <ShoppingHeader
                            setShowOverlay={setShowOverlay}
                            showOverlay={showOverlay}
                            buzz={buzz}
                          />
                        )}
                      {(!isCommentsPage && memoizedBuzz.isNews) && (
                        <>
                          <div
                            className={`${
                              memoizedBuzz.longform_custom_header
                                ? styles.breakingBarWrapper
                                : ''
                            } embed-breaking-bar`}
                          >
                            <BreakingBar
                              edition={memoizedBuzz.country_code}
                              track={breakingBarTrackingFn}
                            />
                          </div>
                          <DesktopStickyAd buzz={memoizedBuzz} />
                        </>
                      )}
                      {isCommentsPage ?
                        <CommentsPost
                          buzz={memoizedBuzz}
                          badges={memoizedBadges}/> :
                        <Post
                          buzz={memoizedBuzz}
                          subbuzzData={memoizedSubbuzzData}
                          badges={memoizedBadges}
                          showOverlay={showOverlay}
                          setShowOverlay={setShowOverlay}
                          giftguideHeader={memoizedGiftguideHeader}
                        />}
                      {(!isCommentsPage && memoizedBuzz.isNews) && (
                        <NewsPageFooter buzz={memoizedBuzz} />
                      )}
                      {!isCommentsPage && <PromoPopup />}
                      {memoizedBuzz.country_code === 'pt-br' && (
                        <CookieBanner />
                      )}
                    </Ads>}
                  </CommentsManager>
                  {!isCommentsPage &&
                    <>
                      <PressBoardAnalytics />
                      <BottomDrawer buzz={memoizedBuzz} />
                    </>}
                </StickyManager>
              </ResizeManager>
            </DestinationWrapper>
          </ABeagleProvider>
        </ConsentProvider>
      </BuzzProvider>
      <BackToTop
        className={styles.backToTop}
        track={useNavActionTracking(memoizedBuzz)}
      />
    </>
  );
});

Page.getInitialProps = async ({ res, req, asPath, query = {} }) => {
  const [path] = asPath.split('?');

  const { error, ...response } = await fetchAPI(
    `${path}.json${objectToQueryString(req?.query || query)}`,
    { req }
  );

  if (error) {
    return error;
  }

  const { redirect } = response;
  if (redirect) {
    return res
      ? res.redirect(redirect.status, redirect.location)
      : Router.replace(redirect.location);
  }

  const {
    badges,
    buzz,
    giftguideHeader,
    header,
    subbuzzes: subbuzzData,
  } = response;

  return {
    namespacesRequired: ['common'],
    badges,
    buzz,
    giftguideHeader,
    header,
    subbuzzData,
  };
};

export default withTranslation('common')(Page);
