import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { CdnImage } from '@buzzfeed/react-components';
import { useUnitTracking } from '../../../../hooks/analytics/useUnitTracking';
import BuzzContext from '../../../../contexts/buzz';
import dynamicImport from '../../../../utils/dynamic-import';
import downsizeImage from '../../../../utils/downsizeImage';
import HeadlineDateline from '../HeadlineDateline';
import styles from './headline-byline.module.scss';
import { isNews } from '../../../../utils/isNews';
import { isDiscussionPage } from '../../../../utils/isDiscussionPage.js';

const DynamicInternetPointsWithNoSSR = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "internet-points" */ '../../../InternetPoints/index.jsx'
    ),
  { ssr: false }
);

function Byline({
  byline,
  advertiserClass,
  index,
  isSingle = false,
}) {
  const buzz = useContext(BuzzContext);
  const isCommunityUser = byline.description_id === '1';
  const authorUrl = isNews(buzz)
    ? `/author/${byline.username}`
    : `/${byline.username}`;

  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Header',
      eventAction: 'link',
      eventLabel: authorUrl,
      unit_type: 'buzz_head',
      unit_name: buzz.id,
      item_name: 'byline',
      item_type: 'profile',
      position_in_unit: index,
      target_content_type: 'user',
      target_content_id: byline.id,
    }),
    [buzz, byline.id, authorUrl, index]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  if (!byline) {
    return null;
  }

  return (
    <div className={`${isNews(buzz) ? styles.newsByline : ''} ${isNews(buzz) && isSingle ? styles.singleByline : ''}`}>
      <a ref={unitEl} href={authorUrl} className={styles.headlineByline}>
        <div className={styles.bylineContainer}>
          <CdnImage
            src={downsizeImage(byline.avatar)}
            className={`${styles.avatar} ${advertiserClass} embed-byline-avatar`}
            alt={byline.display_name}
          />
          <div className={`${styles.bylineText} embed-byline-text`}>
            <span className={`${styles.text} ${advertiserClass}`}>by </span>
            <span
              className={`metadata-link ${styles.bylineName}`}
              dangerouslySetInnerHTML={{ __html: byline.display_name }}
            />
            <p className={styles.position}>{byline.title}</p>
          </div>
        </div>
      </a>
      {isCommunityUser && (
        <div className={styles.internetPointsContainer}>
          <div className={styles.internetPointsContainerInner}>
            <DynamicInternetPointsWithNoSSR
              className={styles.desktopShow}
              userId={byline.id}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function TwinByline({ byline, advertiserClass, isLastByline, index }) {
  const buzz = useContext(BuzzContext);
  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Header',
      eventAction: 'link',
      eventLabel: `/${byline.username}`,
      unit_type: 'buzz_head',
      unit_name: buzz.id,
      item_name: 'byline',
      item_type: 'profile',
      position_in_unit: index,
      target_content_type: 'user',
      target_content_id: byline.id,
    }),
    [buzz, byline.id, byline.username, index]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <a
      ref={unitEl}
      href={`/${byline.username}`}
      className={`${styles.headlineByline} ${styles.twoAuthors}`}
    >
      <div className={styles.bylineContainer}>
        <CdnImage
          src={downsizeImage(byline.avatar)}
          className={`${styles.avatar} ${styles.desktopShow} ${advertiserClass}`}
          alt={byline.display_name}
        />
        <div className={styles.bylineText}>
          <span
            className={`${styles.desktopShow} ${styles.text} ${advertiserClass}`}
          >
            by{' '}
          </span>
          <span
            className={`metadata-link ${styles.bylineName}`}
            dangerouslySetInnerHTML={{ __html: byline.display_name }}
          />
          <p className={styles.desktopShow}>{byline.title}</p>
        </div>
      </div>
      {!isLastByline && <span className={styles.desktopHide}>, </span>}
    </a>
  );
}

function CommunityTeamByline() {
  const avatarURL =
    'https://img.buzzfeed.com/store-an-image-prod-us-east-1/p9aVli648.png';
  const buzz = useContext(BuzzContext);
  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Header',
      eventAction: 'link',
      eventLabel: `/community/about`,
      unit_type: 'buzz_head',
      unit_name: buzz.id,
      item_name: 'byline',
      item_type: 'profile',
      position_in_unit: 1,
      target_content_type: 'feed',
      target_content_id: 'community_about',
    }),
    [buzz.id]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <a
      ref={unitEl}
      href="/community/about"
      className={`${styles.headlineByline} ${styles.communityTeamByline}`}
    >
      <div className={styles.bylineContainer}>
        <CdnImage
          src={downsizeImage(avatarURL)}
          className={`${styles.avatar} ${styles.desktopShow}`}
          alt="BuzzFeed Community Team"
        />
        <div>
          <span className={`${styles.text} ${styles.communityTeamBy}`}>
            Approved and edited by{' '}
          </span>
          <span className={`metadata-link ${styles.bylineName}`}>
            BuzzFeed Community Team
          </span>
        </div>
      </div>
    </a>
  );
}

function TextByline({ byline, className = '', isLastByline, index }) {
  const buzz = useContext(BuzzContext);
  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Header',
      eventAction: 'link',
      eventLabel: `/${byline.username}`,
      unit_type: 'buzz_head',
      unit_name: buzz.id,
      item_name: 'byline',
      item_type: 'profile',
      position_in_unit: index,
      target_content_type: 'user',
      target_content_id: byline.id,
    }),
    [buzz, byline.id, byline.username, index]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <span className={`${styles.textByline} ${className}`}>
      <a className={styles.headlineByline} ref={unitEl} href={`/${byline.username}`}>
        <span
          className={`metadata-link ${styles.bylineName}`}
          dangerouslySetInnerHTML={{ __html: byline.display_name }}
        />
      </a>
      {!isLastByline && ', '}
    </span>
  );
}

function HeadlineByline({
  bylines = [],
  isAd = false,
  buzz = {},
}) {
  const advertiserClass = isAd ? styles.isAdvertiser : '';
  const isModeratedCommunity = buzz.isCommunity && buzz.isModerated;
  const { datelines } = buzz;

  if (isDiscussionPage(buzz)) {
    if (buzz?.username === 'discuss') {
      return null;
    }

    let byline = bylines[0];
    return (
      <TextByline
        key={byline.id}
        byline={byline}
        className={styles.discussionByline}
        isLastByline
        index={0}
     />
    )
  }

  if (isNews(buzz)) {
    return (
      <div className={`${styles.newsHeadlineByline} ${
        bylines.length === 1 && !datelines.length ? [styles.singleHeadlineByline] : ''
      } embed-byline`}>
        <span className={styles.text}>By </span>
        {bylines.map((byline, index) => {
          return (
            <span className={styles.bylineWrap} key={byline.id}>
              <Byline
                key={byline.id}
                byline={byline}
                advertiserClass={advertiserClass}
                index={index}
                isSingle={bylines.length === 1 && !datelines.length}
              />
              {index !== bylines.length - 1 && <span className={styles.text}> and </span>}
            </span>
          );
        })}
        {!!datelines.length && datelines.map(dateline => <HeadlineDateline key={dateline.name} dateline={dateline} />)}
      </div>
    );
  }

  if (bylines.length > 2) {
    return (
      <>
        <span className={styles.text}>by </span>
        {bylines.map((byline, index) => {
          const isLastByline = bylines.length === index + 1;
          return (
            <TextByline
              key={byline.id}
              byline={byline}
              isLastByline={isLastByline}
              index={index}
            />
          );
        })}
      </>
    );
  }

  if (bylines.length === 2) {
    return (
      <>
        <span
          className={`${styles.desktopHide} ${styles.text} ${advertiserClass}`}
        >
          by{' '}
        </span>
        {bylines.map((byline, index) => {
          const isLastByline = index === 1;
          return (
            <TwinByline
              key={byline.id}
              byline={byline}
              advertiserClass={advertiserClass}
              isLastByline={isLastByline}
              index={index}
            />
          );
        })}
      </>
    );
  }

  return bylines.map((byline, index) => (
    <div className={styles.adaptiveBylineContainer} key={byline.id}>
      <Byline
        key={byline.id}
        byline={byline}
        advertiserClass={advertiserClass}
        index={index}
      />
      {isModeratedCommunity && <CommunityTeamByline />}
    </div>
  ));
}

HeadlineByline.propTypes = {
  bylines: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAd: PropTypes.bool.isRequired,
};

export default HeadlineByline;
