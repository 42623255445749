import React, { useContext, useMemo } from 'react';
import styles from './package-new.module.scss';
import { usePackageTracking } from '../../hooks/analytics/usePackageTracking';
import { usePackage } from '../../hooks/usePackage';
import BuzzContext from '../../contexts/buzz';
import { CdnImage } from '@buzzfeed/react-components';
import { isInternalUrl } from '@buzzfeed/bf-utils/lib/internal-url';
import SectionTitle from '../SectionTitle';
import DestinationContext from '../../contexts/destination';
import { isNews } from '../../utils/isNews';

function NewPackage() {
  const buzz = useContext(BuzzContext);
  const packageData = usePackage({ buzz });
  const packageCategory = packageData ? packageData.category : '';

  if (!packageData) {
    return null;
  }
  const items = packageData.results.slice(0, 6);
  let shoppingTitle = 'Your New Favorite Thing';

  return (
    <section
      className={`${styles.shoppingWrapper} ${isNews(buzz) ? styles.bottomShoppingList : ''}`}
    >
      <SectionTitle
        className="new-shopping-package-title"
        id="new-shopping-package-title"
      >
        {shoppingTitle}
      </SectionTitle>
      <ul className={styles.gridNarrow}>
        {items.map((item, index) => (
          <PackageListItem
            key={item.id}
            item={item}
            index={index + 1}
            category={packageCategory}
          />
        ))}
      </ul>
    </section>
  );
}

function PackageItem({
  item,
  index,
  category,
  imageLayout = 'stacked',
  imageSize = 'big',
}) {
  const { destination } = useContext(DestinationContext);
  const targetTracking = {};
  if (isInternalUrl(item.url)) {
    targetTracking.target_content_type = 'buzz';
    targetTracking.target_content_id = item.id;
  } else {
    // for external links
    targetTracking.target_content_url = item.url;
    // for impressions
    targetTracking.target_content_type = 'url';
    targetTracking.target_content_id = item.url;
  }
  const unitTrackingData = useMemo(
    () => ({
      ...item,
      item_name: item.id,
      item_type: index === 0 ? 'splash' : 'card',
      position_in_subunit: index,
      index: index,
      category: category,
      ...targetTracking,
      data_source_name: 'site_component_api',
    }),
    [item, index, category, targetTracking]
  );
  const unitEl = usePackageTracking(unitTrackingData);

  return (
    <article>
      <a
        href={item.url}
        ref={unitEl}
        className={`${styles[imageLayout]} ${styles.shoppingItemLink} ${styles[destination]}`}
      >
        <div className={`${styles.imageWrap} ${styles[imageSize]}`}>
          <CdnImage src={item.image} alt={item.image_alt_text} />
        </div>
        <span className={styles.text}>{item.name}</span>
      </a>
    </article>
  );
}

function PackageListItem({ item = {}, index, category }) {
  return (
    <li>
      <PackageItem item={item} index={index} category={category}></PackageItem>
    </li>
  );
}

export default NewPackage;
