import React, {useEffect, useMemo, useState} from 'react';
import debounce from 'just-debounce-it';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import { addParams } from '@buzzfeed/bf-utils/lib/query-string';
import { GatingModal } from '@buzzfeed/react-components';
import { scrollY } from '../../utils/scroll';
import { isGated } from '../../utils/isGated';
import { useGatingTracking } from '../../hooks/analytics/useGatingTracking';

const BottomDrawer = ({ buzz }) => {
  const [activeModal, setActiveModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const user = cookies.get('bf2-b_info');
  const gated = isGated(buzz);

  const bottomDrawerTrackingData = useMemo(
    () => ({
      item_name: 'gating_modal',
      item_type: 'button',
      target_content_type: 'auth',
      target_content_id: 'sign_in',
    }),
    [buzz.id]
  );

  const trackBottomDrawer = useGatingTracking(
    buzz,
    bottomDrawerTrackingData
  );

  useEffect(() => {
    if (user || !gated) {
      return;
    }

    if (document) {
      setRedirectUrl(addParams(document.location.href, { utm_source: 'sign-in-redirect'}))
    }

    const savedOffset = 2000;
    const onScroll = debounce(() => {
      const scrolled = scrollY();
      if (scrolled > savedOffset && !activeModal) {
        // set z-index to content block one less that GatingModal has
        document.getElementById('buzz-content').style.zIndex = 498;
        document.querySelector('.ad-toolbar').style.zIndex = 498;
        setActiveModal(true);
      }
    }, 100);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };

  }, [buzz.id]);

  if (user || !gated) {
    return null;
  }

  return (
    <GatingModal
      isOpen={activeModal}
      redirectUrl={redirectUrl}
      track={trackBottomDrawer}
    />
  )
};

export default BottomDrawer;
