import { isMobile, isIOSAgent, isIOSMobileAgent, isAndroidAgent } from '@buzzfeed/bf-utils/lib/device';

/**
 * determines mobile based only on size of screen.  This is sufficient for most use cases
 *  but not if truly wanting to do something different based on user being actually on
 *  a mobile device or not
 */
export const isMobileScreenSize = () => isMobile();

/** Mobile device */
export const isMobileDevice = ({ includeTablets = false }) => {
  if (includeTablets) {
    return (isIOSAgent() || isAndroidAgent());
  }
  return (isIOSMobileAgent() || isAndroidAgent());
}
