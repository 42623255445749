import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import { useContext, useEffect, useState } from 'react';
import ABeagleContext from '../../contexts/abeagle';

export function useBullwhip() {
  const [isEnabled, setIsEnabled] = useState(false);
  const { experiments, getFeatureFlagValue } = useContext(ABeagleContext);

  useEffect(() => {
    if (experiments && experiments.loaded) {
      setIsEnabled(getFeatureFlagValue('ads_bullwhip'));
    }
  }, [experiments, getFeatureFlagValue]);

  // load the Bullwhip script if the experiment is enabled
  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    // check if we already loaded the bullwhip script to prevent multiple loads
    const loaded = document.querySelector('script[src*="cdn.bullwhip.cloud"]');
    if (loaded) {
      return;
    }
    // look for the utm_medium=h5d parameter in the URL
    const includesBullwhipParam = (new URLSearchParams(window.location.search)).get('utm_medium') == 'h5d';
    if (includesBullwhipParam) {
      loadScript('https://cdn.bullwhip.cloud/sonar/bf-bf.umd.js');
    }
  }, [isEnabled]);

  return null;
}
