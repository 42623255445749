import React, { useEffect, useRef, useState, useContext } from 'react';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { getNumArray } from '@buzzfeed/adlib/dist/module/bindings/react';
import createInlinePlacer from '@buzzfeed/adlib/dist/module/bindings/react/managers/InlinePlacer';
import createQuizInlinePlacer from '@buzzfeed/adlib/dist/module/bindings/react/managers/QuizInlinePlacer';
import createBFPQuizManager from '@buzzfeed/adlib/dist/module/bindings/react/managers/BFPQuizManager';
import { useT3 } from '../../hooks/useT3';
import ABeagleContext from '../../contexts/abeagle';
import BuzzContext from '../../contexts/buzz';
import AdUnit from '../Ads/AdUnit';
import { isNews } from '../../utils/isNews';
import { CLUSTER } from '../../constants';
import InlinePromoUnit   from '../InlinePromo';
// import { getReferrer } from '@buzzfeed/bf-utils/lib/referrer';
import ElementsPlayer from '../Connatix/Elements';

// list all BFPs which use `bfp-ad-placeholders-ready` message to broadcast status of ads placeholders
const BFP_WITH_ADS = ['ai_quiz', 'scale_quiz', 'tap_on_image', 'quiz'];

const withInlineAds = Component => {
  return function ComponentWithInlineAds(props) {
    const buzz = useContext(BuzzContext);
    const adsContext = useContext(AdsContext);
    const userCountry = adsContext?.env?.userCountry;
    const isNZorAU = userCountry === 'au' || userCountry === 'nz';
    const containerRef = useRef(null);
    const bfpQuizPlacer = useRef(null);
    const [adPortals, setAdPortals] = useState([]);
    const t3App = useT3();
    const pixiedust = props ? props.pixiedust : {};
    // const referrer = getReferrer();
    // const countryCode = buzz.country_code;
    // const facebookHighDensity = (
    //   (countryCode === 'en-us' || countryCode === 'en-ca') &&
    //   referrer === 'facebook' &&
    //   buzz.isShopping === false &&
    //   buzz.isAd === false
    // );
    const {
      experiments,
      getFeatureFlagValue,
      getExperimentValue
    } = useContext(ABeagleContext);
    const disablePromo1Native = getFeatureFlagValue(
      'ads_mweb_quiz_promo-inline1'
    );
    const highDensityNonQuiz = getFeatureFlagValue(
      'ads_high_density_bpage_non_quiz'
    );
    const highDensityQuiz = getFeatureFlagValue(
      'ads_high_density_bpage_quiz'
    );
    const shoppingDensityTest = getExperimentValue(
      'ads_shopping_density_test',
      { rejectErrors: false }
    );
    const rt1520IncreaseDensity = getExperimentValue(
      'RT-1520-density',
      { rejectErrors: false }
    );
    const adDensityOnQuizAndVideoPlayerTest = getExperimentValue(
      'ads-quiz-density-mobile',
      { rejectErrors: false }
    );

    const [bfpAdsMessages, setBfpAdsMessages] = useState([]);
    const [bfpLoadIntervalFinished, setBfpLoadIntervalFinished] = useState(false);

    useEffect(() => {
      setAdPortals([]);
      bfpQuizPlacer.current = null;
    }, [buzz.id]);

    useEffect(
      () => {
        let bfpMessages = [];
        let weightedQuizMessages = [];

        const onBfpT3Message = ({ data }) => {
          if (data.message === 'ukEngineQuiz_Ready' && data.messageData) {
            weightedQuizMessages = getNumArray(
              data.messageData.totalQuestions,
              0
            ).map(i => `bfp-ad-placeholder-${i}-ready`);
          } else if (
            data.message === 'bfp-ad-placeholders-ready' ||
            weightedQuizMessages.includes(data.message)
          ) {
            setBfpAdsMessages(prevState => {
              let messages = [...prevState];
              messages.push(data);
              return messages;
            });
          } else {
            return;
          }

          if (bfpQuizPlacer.current) {
            bfpMessages.forEach(msg => {
              bfpQuizPlacer.current.onBFPReady(msg.messageData);
            });
            bfpMessages = [];
          }
        };

        if (t3App && typeof t3App.on === 'function') {
          t3App.on('message', onBfpT3Message);

          if (buzz.bfpFormats.includes('tap_on_image')) {
            window.BfpT3MessageReady = true;
            document.dispatchEvent(new Event('BfpT3MessageReady'));
          }
        }

        return () => {
          if (t3App && typeof t3App.off === 'function') {
            t3App.off('message', onBfpT3Message);
          }
        };
      },
      [t3App, bfpQuizPlacer] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
      let tmpTime = 10;
      const interval = setInterval(() => {
        tmpTime--;
        if (tmpTime <= 0) {
          clearInterval(interval);
          setBfpLoadIntervalFinished(true);
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [bfpAdsMessages.length]);

    useEffect(() => {
      const countBfpsWithAds = buzz.sub_buzzes
        .filter(sb => sb.form === 'bfp' && BFP_WITH_ADS.indexOf(sb.format_name) !== -1)
        .length;
      // fire message only after recive messages from all bfps on page
      // or if noone was load for last 5 sec
      if ((bfpAdsMessages.length >= countBfpsWithAds || bfpLoadIntervalFinished) && bfpQuizPlacer.current) {
        bfpAdsMessages.sort((a, b) => {
          return (a?.messageData?.subbuzzIndex || 0) - (b?.messageData?.subbuzzIndex || 0);
        }).forEach(msg => {
          bfpQuizPlacer.current.onBFPReady(msg.messageData);
        });
      }
    }, [bfpAdsMessages, bfpQuizPlacer, buzz.id, bfpLoadIntervalFinished]);

    useEffect(() => {
      if (!experiments.loaded) {
        return () => {};
      }

      if (adsContext.status !== 'loaded') {
        return () => {};
      }

      let quizPlacer;
      let inlinePlacer;
      let presetDensity = 2;
      let skipNUnits = false;

      // Inline ads for quizzes are handled separately then inline ads for other subbuzzes.
      if (buzz.is_quiz && !buzz.isBFPQuiz) {
        // RT-649: iOS crash incident
        // IGNORE sticky experiment - and set quiz ad density to less across the board
        // for mobile
        // first ad always after first quiz question; then after each 3 additional
        if (isNZorAU || highDensityQuiz) {
          presetDensity = 1;
        }

        if (adDensityOnQuizAndVideoPlayerTest === 'lower_density') {
          presetDensity = 2;
        }

        quizPlacer = createQuizInlinePlacer({
          AdUnit,
          buzz,
          disablePromo1Native,
          element: containerRef.current,
          onAdsReady: quizAdPortals =>
            setAdPortals(adPortals.concat(quizAdPortals)),
          pixiedust,
          presetDensity,
          skipNUnits,
        });
        quizPlacer.init();
      }

      inlinePlacer = createInlinePlacer({
        AdUnit: InlinePromoUnit,
        buzz,
        element: containerRef.current,
        onAdsReady: inlineAdPortals => {
          setAdPortals(adPortals.concat(inlineAdPortals));
        },
        pixiedust,
        skipNUnits: false,
        presetDensity: (() => {
          // if (facebookHighDensity) {
          //   return 'density_160';
          // }

          if(rt1520IncreaseDensity !== 'control') {
            return 'density_' + rt1520IncreaseDensity;
          }

          if (isNZorAU || highDensityNonQuiz) {
            return 'density_200'
          } else if (shoppingDensityTest === 'variant1') {
            return  'density_200'
          } else if (shoppingDensityTest === 'variant2') {
            return 'density_275'
          }
          return false;
        })(),
        presetPlacements: (() => {
          // if (facebookHighDensity) {
          //   return 'placements_1';
          // }

          if(rt1520IncreaseDensity !== 'control') {
            return 'placements_' + rt1520IncreaseDensity;
          }

          if (isNZorAU || highDensityNonQuiz) {
            return 'placements_1'
          } else if (shoppingDensityTest === 'variant1') {
            return  'placements_1'
          } else if (shoppingDensityTest === 'variant2') {
            return 'placements_2'
          }
          return false;
        })(),
        looseTitles: (() => {
          // only loose titles on increase variant
          if(rt1520IncreaseDensity === 'increase') {
            return true;
          }
          return false;
        })()
      });
      inlinePlacer.init();

      return () => {
        if (inlinePlacer) {
          inlinePlacer.destroy();
        }
        if (quizPlacer) {
          quizPlacer.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buzz.id, adsContext, experiments.loaded]);

    useEffect(() => {
      if (!experiments.loaded) {
        return () => {};
      }

      if (adsContext.status !== 'loaded') {
        return () => {};
      }

      let presetDensity = 2;
      let skipNUnits = false;

      // if buzz is quiz and has at least one bfp format create new AdBFPQuizManager
      if (buzz.is_quiz && buzz.bfpFormats.length > 0) {
        // RT-649: iOS crash incident
        // IGNORE sticky experiment - and set quiz ad density to less across the board
        // for mobile
        // first ad always after first quiz question; then after each 3 additional
        if (isNZorAU || highDensityQuiz || rt1520IncreaseDensity === 'increase') {
          presetDensity = 1;
        }

        if (adDensityOnQuizAndVideoPlayerTest === 'lower_density') {
          presetDensity = 2;
        }

        bfpQuizPlacer.current = createBFPQuizManager({
          AdUnit,
          disablePromo1Native,
          element: containerRef.current,
          onAdsReady: inlineAdPortals =>
            setAdPortals(adPortals.concat(inlineAdPortals)),
          pixiedust,
          presetDensity,
          skipNUnits,
        });
        bfpQuizPlacer.current.init();
      }

      return () => {
        if (bfpQuizPlacer.current) {
          bfpQuizPlacer.current.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buzz.id, adsContext, experiments.loaded]);


    /**
     * Public Good's Action Unit (https://assets.publicgood.com/docs/gen/pgmApi.html)
    */
    useEffect(() => {
      if ( !isNews(buzz) || !containerRef?.current || window.pgmApi || !adPortals.length || adsContext.status === 'disabled' ) {
        return;
      }

      const publicGoodActionUnit = document.createElement('div');
      publicGoodActionUnit.setAttribute('id', 'publicGood');
      publicGoodActionUnit.classList.add('loading', 'clearfix', 'ad-ex');

      const pgmOptions = {
        partnerId: 'buzzfeed-buzzfeed-news',
      };
      if (CLUSTER === 'dev' || CLUSTER === 'stage') {
        pgmOptions.attributes = {
          targetId: '2d3d74ba-3807-4e28-875a-dfc5d67706f8',
          targetType: 'campaign',
        };
      }

      loadScript('https://assets.publicgood.com/pgm/v1/pgm-api.js', document.body).then(async (script) => {
        await script.onload();
        // render Public Good's Unit after mid inline Ad unit
        let midIndex = Math.floor(adPortals.length / 2);
        const nodes = containerRef.current.querySelectorAll('.js-ad-placement');
        nodes[midIndex].insertAdjacentElement('afterend', publicGoodActionUnit);

        window.pgmApi.create(publicGoodActionUnit, {
          ...pgmOptions,
          onShow: () => {
            publicGoodActionUnit.classList.remove('loading');
            const publicGoodTitle = document.createElement('h2');
            publicGoodTitle.classList.add('ad__disclosure--ex');
            publicGoodTitle.innerText = 'ARTICLE CONTINUES BELOW';
            publicGoodActionUnit.insertAdjacentElement('afterbegin', publicGoodTitle);
          },
          onHide: () => {
            window.pgmApi.remove();
            publicGoodActionUnit.remove();
          }
        });
      });
    }, [buzz, adsContext, adPortals, containerRef, loadScript]);

    return (
      <div ref={containerRef}>
        {userCountry !== 'jp' && <ElementsPlayer buzz={buzz} />}
        <Component {...props} />
        {adPortals.map((AdPortal, idx) => (
          <AdPortal key={idx} />
        ))}
      </div>
    );
  };
};

export default withInlineAds;
